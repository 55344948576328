import React from "react";


export const Contact = (props) => {

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>{props.data ? props.data.title : "Get In Touch"}</h2>
                <p>
                  {props.data ? props.data.paragraph : "Loading"}
                </p>
                <img src="img/logo_contact.png" style={{ width: '100%' }} alt="logo"></img>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                <a href={`tel:${props.data ? props.data.phone : "+421 905 012 849"}`}>
                  {props.data ? props.data.phone : "+421 905 012 849"}
                </a>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                <a href={`mailto:${props.data ? props.data.email : "info@deepzone.tech"}`}>
                  {props.data ? props.data.email : "info@deepzone.tech"}
                </a>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data && props.data.address ? props.data.address.split(',').map((part, index) => 
                  <React.Fragment key={index}>
                    {part.trim()}<br />
                  </React.Fragment>
                ) : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-credit-card"></i>
                </span>
                {props.data && props.data.tax ? props.data.tax.split(',').map((part, index) => 
                  <React.Fragment key={index}>
                    {part.trim()}<br />
                  </React.Fragment>
                ) : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"} target="_blank" rel="noreferrer">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.linkedin : "/"} target="_blank" rel="noreferrer">
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"} target="_blank" rel="noreferrer">
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2023 Issaaf Kattan React Land Page Template. {" "}
            <a href="https://github.com/issaafalkattan/React-Landing-Page-Template/blob/master/LICENSE" target="_blank" rel="noreferrer">
              License notice
            </a>
          </p>
          <p>Copyright &copy; 2025 Branislav Frátrik</p>
        </div>
      </div>
    </div>
  );
};

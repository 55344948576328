import React, { useState } from "react";

export const Navigation = (props) => {

  const getLanguagePreference = () => {
    const match = document.cookie.match(/firebase-language-override=([^;]+)/);
    return match ? match[1] : 'en';
  };

  // Get initial language or default to 'en'
  const [language, setLanguage] = useState(
    getLanguagePreference() || "en"
  );

  const handleLanguageChange = (languageCode) => {
    setLanguage(languageCode);
    document.cookie = `firebase-language-override=${languageCode}`;
    window.location.reload(); 
  };

  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
            
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            <img src="img/logo_navbar.png" alt="Deep Zone"></img>
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#features" className="page-scroll">
                {props.data ? props.data.features : "Fetures"}
              </a>
            </li>
            <li>
              <a href="#about" className="page-scroll">
                {props.data ? props.data.about : "About"}
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll">
                {props.data ? props.data.services : "Services"}
              </a>
            </li>
            <li>
              <a href="#portfolio" className="page-scroll">
                {props.data ? props.data.portfolio : "Gallery"}
              </a>
            </li>
            {/*<li>
              <a href="#testimonials" className="page-scroll">
                {props.data ? props.data.testimonials : "Testimonials"}
              </a>
            </li>
            <li>
              <a href="#team" className="page-scroll">
                {props.data ? props.data.team : "Team"}
              </a>
            </li>*/}
            <li>
              <a href="#contact" className="page-scroll">
                {props.data ? props.data.contact : "Contact"}
              </a>
            </li>
            <li className="dropdown">
              <button
                className="btn btn-default dropdown-toggle language-button"
                type="button"
                id="languageDropdown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {language.toUpperCase()} <span className="caret"></span>
              </button>
              <ul className="dropdown-menu" aria-labelledby="languageDropdown">
                <li>
                  <a href="#" onClick={() => handleLanguageChange("en")}>
                    English
                  </a>
                </li>
                <li>
                  <a href="#" onClick={() => handleLanguageChange("sk")}>
                    Slovensky
                  </a>
                </li>
                <li>
                  <a href="#" onClick={() => handleLanguageChange("cs")}>
                    Česky
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

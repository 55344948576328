import React, { useState, useEffect, useRef } from 'react';
import { Carousel } from 'bootstrap';

const ImageCarouselModal = ({ isOpen, onClose, data }) => {
    const [showModal, setShowModal] = useState(isOpen);
    const carouselRef = useRef(null);

    useEffect(() => {
        setShowModal(isOpen);

        // Initialize Bootstrap carousel when the modal opens
        if (isOpen && carouselRef.current) {
            // Assuming you've included Bootstrap's JS
            new Carousel(carouselRef.current);  // Initialize carousel
        }

    }, [isOpen]);

    const handleClose = () => {
        setShowModal(false);
        onClose(); // Call the provided onClose function
    };


    if (!showModal) {
        return null; // Don't render if not open
    }

    return (
        <div className="modal show" tabIndex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <button type="button" className="close" onClick={handleClose} aria-label="Close">
                            <span className="modal-title" style={{ marginRight: '10px' }}>{data ? data.title : "Close me"}</span>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div>
                            <div ref={carouselRef} id="myCarousel" className="carousel slide" data-ride="carousel" data-interval="3000">
                                <ol className="carousel-indicators">
                                    <li data-target="#myCarousel" data-slide-to="0" className="active"></li>
                                    <li data-target="#myCarousel" data-slide-to="1"></li>
                                    <li data-target="#myCarousel" data-slide-to="2"></li>
                                </ol>

                                <div className="carousel-inner">
                                    <div className="item active">
                                        <img src="img/carousel/carousel-img1.jpg" alt="Los Angeles" style={{ width: '100%', opacity: 0.4 }} />
                                        <div className="image-overlay">
                                            <div className="overlay-content"> {/* Added a wrapper for better positioning */}
                                                {data && data.items && data.items.length > 0 && (
                                                    <>
                                                        <h3 className="overlay-title">{data.items[0].title}</h3> {/* Title at the top */}
                                                        <p className="overlay-paragraph">{data.items[0].paragraph}</p> {/* Paragraph below */}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <img src="img/carousel/carousel-img2.jpg" alt="Chicago" style={{ width: '100%', opacity: 0.4 }}></img>
                                        <div className="image-overlay">
                                            <div className="overlay-content"> {/* Added a wrapper for better positioning */}
                                                {data && data.items && data.items.length > 0 && (
                                                    <>
                                                        <h3 className="overlay-title">{data.items[1].title}</h3> {/* Title at the top */}
                                                        <p className="overlay-paragraph">{data.items[1].paragraph}</p> {/* Paragraph below */}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <img src="img/carousel/carousel-img3.jpg" alt="New york" style={{ width: '100%', opacity: 0.4 }}></img>
                                        <div className="image-overlay">
                                            <div className="overlay-content"> {/* Added a wrapper for better positioning */}
                                                {data && data.items && data.items.length > 0 && (
                                                    <>
                                                        <h3 className="overlay-title">{data.items[2].title}</h3> {/* Title at the top */}
                                                        <p className="overlay-paragraph">{data.items[2].paragraph}</p> {/* Paragraph below */}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <a className="left carousel-control" href="#myCarousel" data-slide="prev">
                                    <i className="fa fa-chevron-left"></i>
                                    <span className="sr-only">Previous</span>
                                </a>
                                <a className="right carousel-control" href="#myCarousel" data-slide="next">
                                    <i className="fa fa-chevron-right"></i>
                                    <span className="sr-only">Next</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImageCarouselModal;
